import styled from 'styled-components';

const OurUsersLoveUsStyled = styled.div`
  .our-users-love-us-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .content-title {
      margin-bottom: 24px;
    }
    .content-text {
      margin-bottom: 40px;
      text-align: center;
    }
  }
  .users-testimonials {
  }

  @media (max-width: 768px) {
    .our-users-love-us-content {
      align-items: flex-start;
      .content-title {
        margin-bottom: 16px;
      }
      .content-text {
        padding: 0;
        text-align: left;
      }
    }
  }
`;

export default OurUsersLoveUsStyled;
