'use client';

import React from 'react';
import {
  testimonialsSectionData,
  TestimonialsDataByPageName,
} from '@/data/static/ts/testimonials';
import PrimaryContainer from '@/components/global/containers/PrimaryContainer';
import MainParagraph from '@/components/global/typography/paragraph/MainParagraph';
import OurUsersLoveUsStyled from '@/components/common/sections/OurUsersLoveUs/style';
import DarkHeading2 from '@/components/global/typography/heading/Heading2/DarkHeading2';
import CarouselUsersTestimonials from '@/components/global/carousels/CarouselUsersTestimonials';
import UserTestimonialCard from '@/components/common/sections/OurUsersLoveUs/UserTestimonialCard';

interface IOurUsersLoveUsSectionProps {
  page: TestimonialsDataByPageName;
}

const OurUsersLoveUs = ({ page }: IOurUsersLoveUsSectionProps) => {
  const { title, text, testimonialData } = testimonialsSectionData[page];

  return (
    <PrimaryContainer className={'pb-10'}>
      <OurUsersLoveUsStyled>
        <div className={'our-users-love-us-content'}>
          <DarkHeading2 className={'content-title'}>{title}</DarkHeading2>
          {text && (
            <MainParagraph className={'content-text'}>{text}</MainParagraph>
          )}
        </div>
        <CarouselUsersTestimonials className={'users-testimonials'}>
          {testimonialData.map((singleTestimonial) => {
            return (
              <UserTestimonialCard
                key={singleTestimonial.author}
                {...singleTestimonial}
              />
            );
          })}
        </CarouselUsersTestimonials>
      </OurUsersLoveUsStyled>
    </PrimaryContainer>
  );
};

export default OurUsersLoveUs;
