import React from 'react';
import styled from 'styled-components';
import MainImage from '@/components/global/MainImage';
import MainParagraph from '@/components/global/typography/paragraph/MainParagraph';
import DarkHeading4 from '@/components/global/typography/heading/Heading4/DarkHeading4';
import SemiboldText from '@/components/global/typography/text/SemiboldText';

interface UserTestimonialCardProps {
  text: string;
  author: string;
  avatarUrl: string;
}

const StyledUserTestimonialCard = styled.div`
  background-color: #e9f3f2;
  border: 1px solid #d1e6e5;
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  .quotation-wrapper {
    margin-bottom: 24px;
    width: 27px;
    height: 20px;
    .quotation {
    }
  }
  .card-text-wrapper {
    margin-bottom: 24px;
    display: flex;
    flex-grow: 1;
  }

  .card-meta-wrapper {
    display: flex;
    align-items: center;
    .avatar-wrapper {
      margin-right: 12px;
      width: 52px;
      height: 52px;
      .img-avatar {
        border-radius: 50%;
      }
    }
    .card-author-wrapper {
      .author-name {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
`;

const UserTestimonialCard = ({
  text,
  author,
  avatarUrl,
}: UserTestimonialCardProps) => {
  return (
    <StyledUserTestimonialCard className={'user-testimonial-card'}>
      <div className={'quotation-wrapper'}>
        <MainImage
          className={'quotation'}
          src={'/symptom-checker/quotation.png'}
          alt={'Discover Docus Symptom Checker'}
        />
      </div>
      <div className={'card-text-wrapper'}>
        <MainParagraph className={'mb-0'}>{text}</MainParagraph>
      </div>
      <div className="card-meta-wrapper">
        <div className={'avatar-wrapper'}>
          <MainImage
            className={'img-avatar'}
            src={avatarUrl}
            alt={'Discover Docus Symptom Checker'}
          />
        </div>
        <div className={'card-author-wrapper'}>
          <SemiboldText className={'author-name'}>{author}</SemiboldText>
        </div>
      </div>
    </StyledUserTestimonialCard>
  );
};

export default UserTestimonialCard;
