'use client';

import styled from 'styled-components';
import React, { ReactNode } from 'react';
import { ParagraphProps } from 'antd/es/typography/Paragraph';

const SmallLightGreyParagraphStyled = styled.p`
  color: #7d7f8b !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0 !important;
`;

interface SmallLightGreyParagraphProps extends ParagraphProps {
  children: ReactNode;
}

const SmallLightGreyParagraph: React.FC<SmallLightGreyParagraphProps> = ({
  children,
  ...props
}) => {
  return (
    <SmallLightGreyParagraphStyled {...props}>
      {children}
    </SmallLightGreyParagraphStyled>
  );
};

export default SmallLightGreyParagraph;
